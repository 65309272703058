import React, { Component } from 'react';
import { Link } from 'gatsby'

import { withFirebase } from '../Firebase';

class ComItem extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      form: this.props.form,
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.setState({ loading: true });

      this.props.firebase
      .form(this.props.form)
      .on('value', snapshot => {
        this.setState({
          form: snapshot.val(),
          loading: false,
        });
      });
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentWillUnmount() {
    this.props.firebase.form().off();
  }

  render() {
    const { form, loading } = this.state;

    return (
      <>
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight text-gray-900">
              {form.company}
            </h1>
          </div>
        </header>

        <main>
          {loading && <div className='text-center italic text-2xl font-extrabold text-gray-500 px-4 py-6 sm:px-0'>Loading ...</div>}

          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

            <div className="grid grid-cols-4 sm:grid-cols-1 gap-6 px-4 py-6 sm:px-0">
              <Link to={`/${form.id}/new-employee-request`}>
                <button className='w-full flex justify-center py-4 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'>New Hire Form</button>
              </Link>

              <Link to={`/${form.id}/termination-request`}>
                <button className='w-full flex justify-center py-4 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'>Termination Form</button>
              </Link>
            </div>

          </div>
        </main>
      </>
    );
  }
}

export default withFirebase(ComItem);