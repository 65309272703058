import React from "react"
import { graphql } from "gatsby"
import { compose } from 'recompose';

import Layout from '../components/layout';
import Navigation from '../components/Navigation';
import {
  withAuthorization,
} from '../components/Session';
import ComItem from '../components/Companies/ComItem'

export const clientPageQuery = graphql`
  query ClientBySlug($id: String!) {
    forms(id: { eq: $id }) {
      id
      company
      paperformHire
      paperformTerm
    }
  }
`

const ClientPageBase = ({ form }) => (
  <div>
    <Navigation />

    <ComItem form={form} />
  </div>
)

const condition = authUser => !!authUser;

const ClientPage = compose(
  withAuthorization(condition),
)(ClientPageBase);

const ClientPageTemplate = ({ data }) => {
  return (
    <Layout>
      <ClientPage form={data.forms} />
    </Layout>
  )
}

export default ClientPageTemplate